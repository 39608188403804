<template>
  <div id="configurator" class="main">
    <!--hero section start-->
    <section class="hero-section background-img med-screen" style="background: url('/img/hero-bg-shape-3.svg')no-repeat center center / cover">

      <div class="container">
        <div class="row align-items-center justify-content-between pt-1 pt-sm-1 pt-md-1 pt-lg-0">
          <div class="col-md-12 text-white">
            <h1 class="text-white">Configura il tuo equipaggiamento</h1>
            <p>Scegli l’equipaggiamento e i servizi più adatti ai tuoi team e preparati a partire per il viaggio con Noltech</p>
          </div>
        </div>
      </div>
<!--      <div class="bottom-img-absolute d-none d-sm-block">-->
<!--        <img style="padding-bottom: 30px" src="/img/white_bg2.svg" alt="wave shape" class="img-fluid">-->
<!--      </div>-->
    </section>
    <!--hero section end-->

    <section class="ptb-100">

      <div class="container">
        <div class="section-heading mb-5">
          <h2>Servizi totalmente personalizzati</h2>
          <p class="lead" v-html="'In Noltech ci vantiamo di offrire servizi davvero smart e personalizzati, per questo motivo non trovi i dettagli relativi ai modelli dei device e non riceverai una quotazione in automatico. In base alle preferenze indicate, uno dei nostri consulenti preparerà una proposta ad hoc per la tua azienda indicandoti la disponibilità nel nostro magazzino per un’attivazione e <b>consegna in tempi record</b>.'"/>
        </div>

        <carrello v-if="$store.getters.cart.length > 0"/>

        <div class="device-type form-group">
          <h4>Scegli la tipologia di Dispositivi:</h4>
          <box-scroll class="box-scroll pt-1 pt-sm-1 pt-md-1 pt-lg-0" :step-size="120">
            <div slot="box" v-for="(item, index) in deviceOptions" :key="item.label"
                 @click="setActiveDevice(index)">

              <div class="box-content" :class="{'active' : item.active}">
                <img :src="item.img" :height="60">
                <div class="text-container">
                  <div class="label">{{ item.label }}</div>
                </div>
              </div>

            </div>
          </box-scroll>
        </div>

        <div v-if="rent.device && config.showBand" class="device-band form-group">
          <h4>Fascia dispositivo:</h4>
          <div class="radio-button-container">
            <radio-button
                name="band"
                v-for="item in bandOptions"
                v-model="rent.band"
                :label="item.label"
                :val="item.value"
                :key="item.value"
            ></radio-button>
          </div>
        </div>

        <div class="device-quantity form-group">
          <h4>Quantità:</h4>
          <div class="input-container">
            <my-input
                :type-input="'number'"
                class="quantity"
                :name="'quantity'"
                min="1"
                v-model="rent.quantity"
                :label="''"
            ></my-input>
          </div>
        </div>

        <div class="contract-life form-group">
          <h4>Durata del contratto:</h4>
          <div class="radio-button-container">
            <radio-button
                name="life"
                v-for="item in contractLifeOptions"
                v-model="rent.contractLife"
                :label="item.label"
                :val="item.value"
                :key="item.value"
            ></radio-button>
          </div>
        </div>

        <div class="form-group">
          <h4>Vendor:</h4>
          <box-scroll class="vendor box-scroll pt-1 pt-sm-1 pt-md-1 pt-lg-0" :step-size="120">
            <div slot="box" v-for="(item, index) in config.vendorOptions" :key="index"
                 @click="setActiveVendor(index)">

              <div class="box-content" :class="{'active' : item.active}">
                <img :src="item.img">
              </div>

            </div>
          </box-scroll>
        </div>

        <div class="accessories form-group">
          <h4>Accessori:<img class="info-icon" src="/img/information.svg" :height="15" v-tooltip="'In base ai modelli di device disponibili, ti consiglieremo anche gli accessori più adatti.'"></h4>
          <div class="checkbox-container"
               v-for="item in config.accessoriesOptions"
               :key="item.label"
          >
            <check-box
                class="multiple-checkbox-item"
                :label="'Doc'"
                v-model="item.checked"
            >
              <template slot="custom-label">
                <div class="custom-checkbox-label-template">
                  <img src="/img/lego.svg" :height="40">
                  <span class="label">{{ item.label }}</span>
                </div>
              </template>
            </check-box>
          </div>
        </div>

        <div class="activable-services form-group">
          <h4>Servizi attivabili:</h4>
          <div class="checkbox-container"
               v-for="item in config.activableServicesOptions"
               :key="item.label"
          >
            <check-box
                class="multiple-checkbox-item"
                :label="'Doc'"
                v-model="item.checked"

            >
              <template slot="custom-label">
                <div v-tooltip="item.tooltip ? item.tooltip : ''" class="custom-checkbox-label-template">
                  <img src="/img/lego.svg" :height="40">
                  <span class="label">{{ item.label }}</span>
                </div>
              </template>
            </check-box>
            <img v-if="item.tooltip" v-tooltip="item.tooltip ? item.tooltip : ''" src="/img/information.svg" :height="15"/>
          </div>
        </div>
      </div>


      <div class="container button-container">
        <div class="pt-60">
          <a @click="salvaCarrello" class="btn white-btn page-scroll">Salva e aggiungi altri device</a>
        </div>
        <div v-if="$store.getters.cart.length > 0" class="pt-60">
          <a @click="richiediPreventivo" class="btn solid-btn page-scroll">Richiedi il preventivo</a>
        </div>
      </div>

    </section>

  </div>
</template>

<script>
  import RadioButton from './global/RadioButton'
  import CheckBox from './global/CheckBox'
  import BoxScroll from 'elmecboxscroll/src/components/BoxScroll'
  import {commonMixins} from '../mixins/common-mixins'
  import Carrello from './Carrello'
  import swal from 'sweetalert2'
  import MyInput from './global/MyInput'

  export default {
    name: 'Configurator',
    props: {
      deviceType: {
        type: String
      },
      deviceQuantity: {
        type: [String, Number],
        default: 0
      }
    },
    mixins: [commonMixins],
    components: {MyInput, Carrello, BoxScroll, CheckBox, RadioButton},
    watch: {
      // var: {
      //   handler: function () {
      //     this.$nextTick(() => {
      //
      //     })
      //   },
      //   deep: true
      // }
    },
    data () {
      return {
        requiredFields: ['device', 'band', 'quantity', 'contractLife', 'vendor'],
        config: {
          showBand: true,
          vendorOptions: [],
          accessoriesOptions: [],
          activableServicesOptions: []
        },
        rent: {
          device: '',
          band: '',
          quantity: 0,
          contractLife: '',
          vendor: '',
          accessories: [],
          activableServices: []
        },
        bandOptions: [
          { label: 'Business standard', value: 'Business standard'},
          { label: 'Top class', value: 'Top class'}
        ],
        contractLifeOptions: [
          { label: '1 mesi', value: '1 mesi'},
          { label: '6 mesi', value: '6 mesi'},
          { label: '12 mesi', value: '12 mesi'},
          { label: '24 mesi', value: '24 mesi'},
          { label: '36 mesi', value: '36 mesi'},
          { label: '48 mesi', value: '48 mesi'},
          { label: '60 mesi', value: '60 mesi'}
        ]
      }
    },
    created() {
      this.resetConfig(true)
      this.goTopPage()
    },
    mounted: function () {
      if (this.deviceType) {
        let index = -1
        this.deviceOptions.forEach((item, i) => {
          if (item.value === this.deviceType) index = i
        })

        this.setActiveDevice(index)
      }

      if (this.deviceQuantity) {
        this.rent.quantity = this.deviceQuantity
      }
    },
    methods: {
      resetConfig: function (exclusion) {
        this.rent = {
          device: exclusion ? this.rent.device : '',
          band: '',
          quantity: 0,
          contractLife: '',
          vendor: '',
          accessories: [],
          activableServices: []
        }

        if (!exclusion) {
          this.setActiveDevice(-1)
        }

        // this.setActiveVendor(-1)
        // this.uncheckAllBox('accessoriesOptions')
        // this.uncheckAllBox('activableServicesOptions')
        this.setConfiguration()
      },
      setConfiguration: function () {
        for (let attr in this.config) {
          this.config[attr] = JSON.parse(JSON.stringify(this.getConfig(this.rent.device, attr)))
        }

        if (this.config['vendorOptions'].length === 1) {
          this.setActiveVendor(0)
        }
      },
      validateFields: function () {
        let valid = true
        this.requiredFields.forEach(field => {
        if (field === 'band') {
            if (this.config.showBand && !this.rent[field]) {
              valid = false
            }
          } else if (isNaN(this.rent[field])) {
            if (this.rent[field] <= 0) {
              valid = false
            }
          } else if (!this.rent[field]) {
            valid = false
          }
        })
        return valid
      },
      salvaCarrello: function () {
        if (this.validateFields()) {
          this.config.accessoriesOptions.forEach(item => {
            if (item.checked) {
              this.rent.accessories.push(item.label)
            }
          })
          this.config.activableServicesOptions.forEach(item => {
            if (item.checked) {
              this.rent.activableServices.push(item.label)
            }
          })

          this.$store.dispatch('addCart', this.rent)
          this.resetConfig()
        } else {
          swal({
            title: 'Attenzione',
            text: 'Tutti i campi devono essere compilati per poter continuare.',
            type: 'error'
          })
        }
      },
      richiediPreventivo: function () {
        this.$router.push({
          name: 'PreventivoNoleggio'
        })
      },
      // uncheckAllBox: function (attribute) {
      //   this.config[attribute] = this.config[attribute].filter(item => {
      //     item.checked = false
      //     return item
      //   })
      // },
      setActiveDevice(index) {
        if (this['deviceOptions']) {
          this['deviceOptions'] = this['deviceOptions'].filter(item => {
            item.active = false
            return item
          })

          if (this['deviceOptions'][index]) {
            this['deviceOptions'][index].active = true
            this.rent.device = this['deviceOptions'][index].value
            this.resetConfig(true)
          }
        }
      },
      setActiveVendor(index) {
        if (this.config['vendorOptions']) {
          this.config['vendorOptions'] = this.config['vendorOptions'].filter(item => {
            item.active = false
            return item
          })

          if (this.config['vendorOptions'][index]) {
            this.config['vendorOptions'][index].active = true
            this.rent.vendor = this.config['vendorOptions'][index].value
          }
        }
      }
    }
  }
</script>

<style lang="less">

#configurator {
  .info-icon {
    margin-left: 7px;
  }

  .med-screen {
    min-height: 35vh;
    position: relative;
    width: 100%;
    z-index: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .text-white {
      text-align: center;
    }
  }

  h4 {
    margin-bottom: 10px;
    color: #2C5693;
  }

  .form-group {
    margin-top: 25px;

    &.device-type {
      @media (min-width: 700px) and (max-width: 1100px)  {
        width: 50%;
      }
      @media (min-width: 1100px)  {
        width: 70%;
      }
    }
    &.device-quantity {
      .input-container {
        width: 200px;
      }
    }
  }

  .box-scroll {
    &.vendor {
      .box-content {
        height: 100px;
        width: 100px;
        justify-content: center;

        img {
          max-height: 60px;
          max-width: 80px;
        }
      }
    }

    #box-left-arrow {
      padding-left: 0;
    }
    #box-right-arrow {
      padding-right: 0;
    }

    .box-content {
      background-color: #FCFCFC;
      border: 1px solid #2C5693;
      border-radius: 10px;
      height: 160px;
      width: 120px;
      padding: 20px 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      margin: 10px 10px;

      &.active {
        border: 4px solid #2C5693;
        padding: 17px 5px;

        .text-container {
          .label {
            font-weight: bold;
          }
        }
      }

      .text-container {
        margin-top: 10px;

        .label{
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* number of lines to show */
          -webkit-box-orient: vertical;
          color: #2C5693;
          font-size: 14px;
        }
      }
    }
  }

  .my

  .radio-button-container {
    display: flex;

    .radio-button {
      margin-right: 20px;
    }
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 7px;

    .multiple-checkbox-item {
      width: auto;
      height: 40px;
      align-items: center;

      .checkbox-label {
        margin-bottom: 15px;
      }

      .custom-checkbox-label-template {
        margin-left: 20px;
        display: flex;
        align-items: center;
        pointer-events: none;
        color: #33475b;

        .label {
          margin: 0 10px;
        }
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .button-container {
    display: flex;
    justify-content: space-evenly;

    @media (max-width: 900px)  {
      flex-direction: column;
      align-items: center;
    }

    .btn {
      cursor: pointer;
    }

    .white-btn {
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1px;
      border-radius: 5px;
      background-color: white;
      color: gray;
      border: 2px solid gray;
      padding: 18px 50px;
      height: 55px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
    }
  }
}

</style>
