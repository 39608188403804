<template>
    <label class="radio-button">
        <div class="testo-radio">
            <span :for="id">{{label}}</span>
        </div>
        <input type="radio" checked="checked" ref="inp"
               v-bind:id="id" v-model="selected" v-bind:value="val" v-bind:disabled="setDisabled" :name="name"
               @click="updateValue()" :class="theme">
        <span class="checkmark" :class="[theme, {'active': val === value}]"></span>
    </label>
</template>

<script>
export default {
  name: 'radio-button',
  props: {
    value: { type: String },
    val: { type: String },
    label: { type: String },
    setDisabled: { type: Boolean, default: false },
    name: { type: String, default: '' },
    customWidth: { type: Number },
    theme: { type: String, default: 'blue' }
  },
  watch: {
    value: function () {
      this.selected = this.value
    }
  },
  mounted: function () {
    this.id = this.random()
    this.selected = this.value
  },
  data () {
    return {
      id: 0,
      selected: ''
    }
  },
  methods: {
    random: function () {
      return parseInt(Math.random() * 100000)
    },
    updateValue: function () {
      this.$emit('input', this.$refs.inp.value)
    }
  }
}
</script>

<style lang='less'>
    .radio-button { /* Create a custom radio button */
        max-width: 350px;
        display: block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        :hover input ~ .checkmark { /* On mouse-over, add a grey background color */
            background-color: #ccc;
        }

        input { /* Hide the browser's default radio button */
            position: absolute;
            opacity: 0;
            cursor: pointer;

            &:checked ~ .checkmark:after { /* Show the indicator (dot/circle) when checked */
                display: block;
            }
        }

        .checkmark { /* Create a custom radio button */
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #eee;
            border-radius: 50%;
            margin-top: 2px;
            border: 1px solid #999999;

            &.blue {
                &.active { /* When the radio button is checked, add a blue background */
                    border: 4px solid #eee;
                    background-color: #2C5693;
                    box-shadow: 0 0 0 1px #999999;
                }
            }
            &.red {
                &.active { /* When the radio button is checked, add a red background */
                    border: 5px solid red;
                }
            }
            &.orange {
                &.active { /* When the radio button is checked, add a orange background */
                    border: 5px solid orange;
                }
            }
            &.yellow {
                &.active { /* When the radio button is checked, add a orange background */
                    border: 5px solid #FCCC2D;
                }
            }
            &.green {
                &.active { /* When the radio button is checked, add a green background */
                    border: 5px solid green;
                }
            }
            &.light-blue {
                &.active { /* When the radio button is checked, add a light-blue background */
                    border: 5px solid #8fddfd;
                }
            }

            /*&:after { !* Create the indicator (the dot/circle - hidden when not checked) *!
                content: "";
                position: absolute;
                display: none;

                top: 4px;
                left: 4px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: white;
            }*/
        }

        .testo-radio {
            padding-left: 24px;

            span {
                color: #33475b;
            }
        }
    }
</style>
